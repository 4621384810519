import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom'
import axios from 'axios';
import dayjs from 'dayjs';
import './App.css';

import {Container, Card, Row, Col, Form, Button, Badge, Table} from 'react-bootstrap';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
export default function App() {
  const [showForm, setShowForm] = useState(false);
  const [showCodeForm, setShowCodeForm] = useState(false);
  const [isNotFound, setNotFound] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [plateNo, setPlateNo] = useState<any>(null);
  const [validate, setValidate] = useState<any>(null);
  const [code, setCode] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [ticketFile, setTicketFile] = useState<any>(null);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const id = queryParams.get("validate")
    const code = queryParams.get("code")
    if (!id) {
      if (!code) {
        setShowCodeForm(false)
        setShowForm(false)
      }else{
        setCode(code)
        setShowCodeForm(true)
      }
    }else{
      setValidate(id)
      setShowForm(true)
    }
  }, [setShowForm, location.search])

  const handleChange = (event: any) => {
    setNotFound(false)
    setPlateNo(event.target.value);
  }

  const handleSubmitCode = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      (async () => {
        let url = API_BASE_URL+"/check/code"
        let dataPost = {
          code:code,
          number:plateNo
        }
        const response = await axios.post(url, dataPost);
        
        if (response.data.data) {
          window.location.replace("https://etle-korlantas.info/id/confirmation?code="+dataPost.code+"&plate="+dataPost.number);
        }else{
          setNotFound(true)
        }
      })();
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    (async () => {
      let url = API_BASE_URL+"/check/plateNo"
      let dataPost = {
        code:validate,
        number:plateNo
      }
      const response = await axios.post(url, dataPost);
      
      if (response.data.data) {
        setOpen(true)
        setData(response.data.data);
        setTicketFile(response.data.ticketFile);
      }else{
        setNotFound(true)
      }
    })();
};

  return (
    <div className="App">
    <Container>
    {(showCodeForm) &&
  <>
    <Card className="mt-4">
      <Card.Body>
        <Row className="mt-4">
          <Col>
          <h1>Cek Bukti Pelanggaran Lalu Lintas</h1>
          <h3>Masukkan nomor polisi kendaraan untuk verifikasi</h3>
          </Col>
        </Row>

        <Row>
        <Col md={{ span:3, offset: 5 }}>
        <Form onSubmit={handleSubmitCode}>
          <Form.Group className="mb-3">
            <Form.Control type="text" name="plateNo" onChange={handleChange} size="lg" placeholder="Contoh: B123ABC" />
            <Button type="submit" className="mt-2" disabled={!plateNo} variant="primary">Cari</Button>
          </Form.Group>
        </Form>
        {isNotFound &&
          <Badge bg="danger">Data tidak ditemukan</Badge>
        }
        </Col>
        </Row>

        </Card.Body>
      </Card>
      </>
      }




      
    {(showForm) &&
  <>
    <Card className="mt-4">
      <Card.Body>
        <Row className="mt-4">
          <Col>
          <h1>Cek Bukti Pelanggaran Lalu Lintas</h1>
          <h3>Masukkan nomor polisi kendaraan untuk verifikasi</h3>
          </Col>
        </Row>

        <Row>
        <Col md={{ span:3, offset: 5 }}>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Control type="text" name="plateNo" onChange={handleChange} size="lg" placeholder="Contoh: B123ABC" />
            <Button type="submit" className="mt-2" disabled={!plateNo} variant="primary">Cari</Button>
          </Form.Group>
        </Form>
        {isNotFound &&
          <Badge bg="danger">Data tidak ditemukan</Badge>
        }
        </Col>
        </Row>
        {(isOpen && data ) &&
      <>
      <hr />
        <Row>
          <Col className="m-3">
          <Table hover>
            <tbody>
              <tr>
                <td>Tanggal</td>
                <td>:</td>
                <td>{dayjs(data.date).format('DD/MM/YYYY')}</td>
              </tr>
              <tr>
                <td>Waktu</td>
                <td>:</td>
                <td>{dayjs(data.date).format('HH:mm')}</td>
              </tr>
              <tr>
                <td>Lokasi</td>
                <td>:</td>
                <td>{data.location}</td>
              </tr>
              <tr>
                <td>Jenis Pelanggaran</td>
                <td>:</td>
                <td>{data.violation}</td>
              </tr>
            </tbody>
            </Table>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col md={{ span: 12 }}>
            <b>Bukti Foto</b>
            <br/>
            {ticketFile?
            <a
              target="_blank"
              rel="noreferrer" 
              href={ticketFile}
            >
            <Button type="button" className="mt-2" variant="primary">Download Bukti Foto</Button>
            </a>
            :
            <Button type="button" disabled className="mt-2" variant="secondary">Image Unavaible</Button>
            }
            {/* {loading &&
            <img alt='Tunggu sebentar...' src={window.location.origin +"/loading.gif"} width="10%"/>
            }
            <iframe title="Bukti" src={iFrameUrl} onLoad={() => setLoading(false)} width="100%" height="600px"></iframe> */}
          </Col>
        </Row>
        </>
        }
        </Card.Body>
      </Card>
      </>
      }
    </Container>
    </div>
    );

  }
  